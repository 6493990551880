 /* Will edit everything selected including everything between a range of dates */

.CalendarDay__selected_span {
  background: #F9ECF2;
  color: #0E0E0E;
}

 /* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  color: white;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #201751;
  color: white;
}

/* Will edit when the second date (end date) in a range of dates */

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {

}

.DateInput {
  width: 45%;
  border-radius: 10px;
  background: none;
  overflow-x: visible;
  height: 100%;
}

.DateInput_input {
  background: none;
  overflow-x: visible;
  height: 100%;
  font-size: 14px;
  font-family: Poppins;
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  color: #2F2F2F; /* gray 2 */
}

.DateInput_input::placeholder {
  font-family: Poppins;
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  color: #585858; /* gray 3 */
}

.DateInput_input__focused  {
  border-bottom: 5px solid #D91473;
  width: 100%;
}

.DateRangePickerInput__withBorder {
  border: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  border: none;
  align-items: center;
  background: none;
}

#startDate {
  padding: 10px 0 10px 15px;
  background: none;
  height: 50px;
  margin: "0"
}

#endDate {
  /* width: 135px; */
  padding-left: 20%;
  background: none;
  height: 50px;
  margin: "0"
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
}


.SingleDatePicker {
  width: 100%;
}

.SingleDatePickerInput__withBorder {
  border-radius: 10px;
  width: 100%;
  border: none;
}

.DateRangePicker_picker {
  z-index: 999 !important;
  margin-top: 100px;
  height: calc(100% - 100px);  
}

@media screen and (min-width: 680px) {
  .DateRangePicker_picker {
    z-index: 999 !important;
    margin-top: 0;
    height: auto;  
  }
}



